import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";

import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

export const frontmatter = {
  title: "Día 15",
  week: "Semana 3",
  month: "Mar",
  day: "28",
  monthNumber: "03",
  date: "2020-03-28",
  path: "/cronologia/semana-03#dia-28-mar",
};

const Day15 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 8.189 nuevos positivos y 832 personas fallecidas.
          Permanecen hospitalizadas 40.648 personas, 4.355 más que el día
          anterior, y 2.405 reciben el alta. El número de casos ingresados en la
          UCI asciende a 4.575, lo que supone un aumento de 410 personas.
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 7,9 % y la tasa de recuperados del 15,1 %.
        </ModText>
        <ModText>
          El Gobierno endurece las medidas de confinamiento con la finalidad de
          descongestionar las UCI en los hospitales: todos los trabajadores de
          actividades no esenciales deberán quedarse en casa en las próximas dos
          semanas. Esta medida, generada por las recomendaciones del Comité
          Científico Técnico Covid-19, se extiende desde el 30 de marzo al 9 de
          abril.
        </ModText>
        <ModTwoCols
          src="/images/svg/28_mar_avion.svg"
          alt="avion en vuelo"
          small={false}
          inverted={false}
        >
          <strong>
            Llega un primer envío con cerca de 1.200.000 mascarillas
          </strong>{" "}
          adquiridas por Ministerio de Transportes, Movilidad y Agenda Urbana
          (Mitma) para personal sanitario y trabajadores del sector transporte.
          La adquisición de este envío se ha realizado por el procedimiento de
          emergencia, por el que se adoptan medidas urgentes para responder al
          impacto económico del COVID-19.
        </ModTwoCols>
        <ModText>
          Asimismo, el Ministerio del Interior ha comenzado este sábado a
          distribuir una nueva partida de equipos de protección personal, que en
          total asciende a 1.125.000 mascarillas homologadas FFP1 y FFP2, entre
          la Policía Nacional, la Guardia Civil e Instituciones Penitenciarias.
        </ModText>
        <ModAnimation svg="/images/anim/28_mar_hospital.svg" />
        <ModText>
          El <strong>Instituto de Salud Carlos III (ISCIII)</strong> ha
          comenzado a financiar proyectos de investigación del denominado Fondo
          COVID-19. Los dos primeros ensayos, enfocados a fases precoces de la
          enfermedad en pacientes hospitalizados, buscan frenar o evitar el
          desarrollo de la fase inmunológica, responsable final de que sea
          necesario utilizar soporte ventilatorio avanzado e ingreso en UCI.
        </ModText>
        <ModTwoCols
          src="/images/svg/28_mar_transportes.svg"
          alt="vehículos servicio público"
          small={true}
          inverted={true}
        >
          El{" "}
          <strong>Ministerio de Transportes Movilidad y Agenda Urbana</strong>{" "}
          acuerda flexibilizar los tiempos de conducción y descanso a los
          conductores que realicen operaciones de transporte de mercancías en
          todo el territorio nacional hasta el próximo 12 de abril.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/28_mar_ministerio.svg"
          alt="edificio ministerio"
          small={true}
          inverted={false}
        >
          El{" "}
          <strong>
            Ministerio de Asuntos Exteriores, Unión Europea y Cooperación
          </strong>
          continúa trabajando intensamente para asistir a todos los españoles
          que aún están intentando regresar a España con un repartidos por las
          Embajadas y Consulados de España atienden las 24 horas del día a
          través del teléfono, el correo electrónico y las redes sociales.
        </ModTwoCols>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day15;
