import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day15 from "./dia-28-mar";
import Day16 from "./dia-29-mar";
import Day17 from "./dia-30-mar";
import Day18 from "./dia-31-mar";
import Day19 from "./dia-01-abr";
import Day20 from "./dia-02-abr";
import Day21 from "./dia-03-abr";
import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";

const Week = (props) => {
  return (
    <>
      <LayoutWeek {...props}>
        <SecWeekly period="28 Marzo - 03 Abril" week="Semana 3">
          <SecWeeklyColumn>
            Las <strong>Fuerzas Armadas</strong> ponen en marcha{" "}
            <strong>17 hospitales de campaña</strong> en España
            <ModDottedLine />
            El Gobierno solicita la prórroga del{" "}
            <strong>estado de alarma hasta el próximo 25 abril</strong>
            <ModDottedLine />
            Las <strong>llamadas al 016</strong> aumentan un 12,43%
            <ModDottedLine />
            Primer <strong>candidato de vacuna</strong> a finales de abril
          </SecWeeklyColumn>
          <SecWeeklyColumn>
            <strong>DataCOVID,</strong> un estudio de movilidad para{" "}
            <strong>analizar</strong> los <strong>desplazamientos</strong> de la
            población
            <ModDottedLine />
            Los centros sanitarios realizan entre
            <strong>15.000 y 20.000 pruebas</strong> de diagnóstico diarias
            <ModDottedLine />
            <strong>ALOJA,</strong> servicio solidario para{" "}
            <strong>asistir a los españoles</strong> que se encuentran
            bloqueados <strong>en el extranjero</strong>
          </SecWeeklyColumn>
        </SecWeekly>
        <Day21 {...props} />
        <Day20 {...props} />
        <Day19 {...props} />
        <Day18 {...props} />
        <Day17 {...props} />
        <Day16 {...props} />
        <Day15 {...props} />
      </LayoutWeek>
    </>
  );
};

export default Week;
